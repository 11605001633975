import * as React from "react";
import { Default } from "../layout";

const Home = () => (
  <Default>
    <p>Hi there 👋</p>
  </Default>
);

export default Home;
